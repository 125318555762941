//Import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

//Import Icons
@import "./style.scss";

.slick-dots {
  padding-top: 15px !important;
  li {
    text-decoration: none !important;
    button {
      width: 12px !important;
      height: 4px !important;
      margin: 5px 7px !important;
      background: $accent !important;
      border: none !important;
      border-radius: 20px !important;
      padding: 0px !important;
      &:before {
        content: none !important;
      }
    }
  }
}

.slick-dots {
  li.slick-active {
    button {
        background-color: $dark !important;
    }
  }
}

.bg-overlay-hero{
    background-color: #000;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    height: 103%;
    width: 100%;
}

.text-left {
  text-align: left !important;
}
.size-full{
  width: 100%;
}