//
// general.scss
//

body {
  font-family: $font-family-base;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  line-height: 2;
}

.font-primary {
  font-family: $font-family-base;
}

.row > * {
  position: relative;
}
h4.subtitle{
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3pt;
  color:$body-color;
  font-weight: 500 !important;
}
h4.subtitle a{
  color:$body-color !important;
}

h3.decorate{
  position: relative;
  font-weight: 300;
  color:$secondary !important;
  font-size: calc(1.65rem + 1vw);
  line-height: calc(1.35rem + 1vw);
}

h3.decorate:before{
  content: "";
  position: absolute;
  left: -327px;
  top: 19px;
  z-index: 12;
  width: 305px;
  height: 2px;
  background-color: $secondary;
}

h3.decorate:after{
  content: "";
  position: absolute;
  left:-24px;
  top:15px;
  z-index: 12;
  width:10px;
  height:10px;
  border-radius: 6px;
  background-color: $secondary;
}
h3.decorate-primary{
  position: relative;
  font-weight: 300;
  color:$primary !important;
  font-size: calc(1.65rem + 1vw);
  line-height: calc(1.35rem + 1vw);
}

h3.decorate-primary:before{
  content: "";
  position: absolute;
  left: -327px;
  top: 19px;
  z-index: 12;
  width: 305px;
  height: 2px;
  background-color: $primary;
}

h3.decorate-primary:after{
  content: "";
  position: absolute;
  left:-24px;
  top:15px;
  z-index: 12;
  width:10px;
  height:10px;
  border-radius: 6px;
  background-color: $primary;
}

h3.decorate.text-center{
  color:$dark;
  letter-spacing: 3px;
  font-weight: 400;
}

h3.decorate.text-center:before{
  content: "";
  position: absolute;
  left: 25%;
  right: auto;
  top: auto;
  bottom:-10px;
  z-index: 12;
  width: 50%;
  height: 2px;
  background-color: $secondary;
}

h3.decorate.text-center:after{
  ontent: "";
  position: absolute;
  left: 50%;
  right: auto;
  top: auto;
  bottom:-14px;
  margin-left: -6px;
  z-index: 12;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background-color: $secondary;
}
.dconex-title{
  font-weight: 300 !important;
}
.dconex-title strong{
  font-weight: 600 !important;
}

.about-by ul{
  list-style:none;
  padding: 0;
}

.about-by li span{
  font-size:20px;
  font-weight: bold;
  width:50px;
  line-height: 30px;
  display: inline-block;
}

.bg-contact{
  background-color: $secondary !important;
}

.txt-dark{
  color: $dark !important;
}
.text-light-green{
  color:$custom !important;
}

#projects .img-fluid{
  height:150px;
}

.slick-prev:before,
.slick-next:before
{
  color: $accent !important;
  line-height:20px !important;
  font-size: 30px !important;
}
.slick-prev:hover:before,
.slick-next:hover:before
{
  color: $dark !important;
}