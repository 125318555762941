//
// variables.scss
//


@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

// Color variables
$white:                     #ffffff;
$black:                     #000000;

$primary:                   #5fb793;
$secondary:                 #1D5756;
$accent:                    #B0D174;
$dark:                      #13343B;
$body-color:                #5fb793;
$custom:                    #50BDBA;

$success:                   #12925b;
$info:                      #3bc3e9;
$warning:                   #ffbb44;
$danger:                    #ea553d;
$muted:                     #cccccc;
$light:                     #C1F6ED;


// Body Background
$body-bg:                   #C1F6ED;

$font-family-base:            'Rubik', sans-serif;